
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import CreateUi from "@/components/Core/Create/CreateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { IMaterialShadeService } from "@/services";

@Component({
  components: {
    CreateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class MaterialShadeCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenValues!: string[];

  get materialShadeService() {
    return this.container.resolve<IMaterialShadeService>(
      S.MATERIAL_SHADE_SERVICE
    );
  }

  loading: boolean = false;
  shadeValue: string | null = null;

  async create() {
    try {
      this.loading = true;
      const createdShade = await this.materialShadeService.create({
        shadeValue: this.shadeValue!,
      });
      this.$emit("create", createdShade);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(shadeValue: string) {
    return (
      _.findIndex(
        this.forbiddenValues,
        (s) => s.toUpperCase() == shadeValue.toUpperCase()
      ) != -1
    );
  }
}
